import './App.css';
import ProductCard from './component/product-card/product-card.component';


function App() {
  return (
    <div className="App">
      <ProductCard></ProductCard>
    </div>
  );
}

export default App;
